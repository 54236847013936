import { Badge, Button, ButtonGroup, Col, Container, Form, Row, Spinner, Stack, Table, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { allSettled, fetch_iex, fetch_machines, fetch_summary, fetch_summary_monthly } from "../common/fetcher";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Area, AreaChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateInputView, GridBoxView, GridBoxViewColumn, LabelBadge, SpinnerView, YearDropdown } from "../components/CommonViews";
import { chunk, f_comma_num, f_num, round_decimals } from "../common/utility";
import { get_config_vars } from "../common/serverconfig";

export function DayMachines(props) {
  const navigate = useNavigate();
  let { state } = useLocation();
  let query = useQuery("fetch_machines", () => fetch_machines(), {
    onSuccess: (data) => {
      if (data && data.length === 1) navigate("machine", { state: { data: data[0] } });
    },
  });

  if (query.isLoading) return <SpinnerView />;
  else if (query.error) return "Error";

  return (
    <Container fluid className="my-5">
      <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
        {query.data &&
          query.data.map((d, i) => {
            if (d && d.data && d.data.name) {
              return (
                <Link to={`machine`} state={{ data: d }}>
                  <Button key={`DayMachines_${i}`} variant={`${state && state.data && state.data.data._id === d.data._id ? "light" : "outline-light"}`} className="p-3">
                    {d.data.name}
                  </Button>
                </Link>
              );
            }
            return null;
          })}
      </Stack>
      <Outlet />
    </Container>
  );
}

const ReportContext = createContext();

export function ReportProvider(props) {
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();

  const [selected_mc, setselected_mc] = useState([]);

  const [mcs, setmcs] = useState();

  let query2 = useQuery("config_vars", get_config_vars);
  let color_accent = useMemo(() => (query2.data && query2.data.COMPANY_COLOR) ?? "#138AF2", [query2.data]);

  useEffect(() => {
    if (selected_mc.length) {
      sessionStorage.setItem("selected_mc", JSON.stringify(selected_mc));
    }
  }, [selected_mc]);

  useEffect(() => {
    if (startdate) sessionStorage.setItem("startdate", startdate.toISOString());
  }, [startdate]);

  useEffect(() => {
    if (enddate) sessionStorage.setItem("enddate", enddate.toISOString());
  }, [enddate]);

  useEffect(() => {
    let d = sessionStorage.getItem("selected_mc");
    if (d) setselected_mc(JSON.parse(d));

    let s = sessionStorage.getItem("startdate");
    setstartdate(s ? moment(s) : moment().startOf("month"));

    let e = sessionStorage.getItem("enddate");
    setenddate(e ? moment(e) : moment());
  }, []);

  let query_machines = useQuery("fetch_machines", () => fetch_machines(), {
    onSuccess: (data) => {
      if (selected_mc.length === 0 && data && Array.isArray(data)) {
        let temp = data.map((d) => d && d.data && d.data._id);
        setselected_mc([...temp]);
      }
    },
  });

  let query_summary = useQuery(
    ["fetch_summary", startdate, enddate, selected_mc],
    () => {
      function execute(type) {
        let obj = {};
        let arr = [];
        selected_mc.forEach((m) => {
          let d = query_machines.data.find((f) => f && f.data && f.data._id === m);
          if (typeof d === "object" && d.data && d.data.type === type) {
            if (!obj[d.server]) obj[d.server] = [];
            obj[d.server].push(d.data._id);
            arr.push(d);
          }
        });

        // query_machines.data.forEach((d) => {
        //   if (typeof d === "object" && d.data && d.data.type === type) {
        //     if (!obj[d.server]) obj[d.server] = [];
        //     obj[d.server].push(d.data._id);
        //   }
        // });

        let promises = [];
        for (const [k, v] of Object.entries(obj)) {
          promises.push(fetch_summary(k, v, startdate, enddate, true));
        }

        let promises_final = allSettled(promises).then((res) => {
          return res.map((r) => r.value);
        });

        return { promises_final, arr };
      }

      let gens = execute("gen");
      let cons = execute("con");

      setmcs(() => {
        return { gen: gens.arr, con: cons.arr };
      });
      return Promise.all([gens.promises_final, cons.promises_final]);
    },
    { enabled: typeof query_machines.data === "object" ? true : false }
  );

  let query_config = useQuery("config_vars", get_config_vars);

  let query_iex = useQuery(
    ["fetch_iex", query_machines.data, selected_mc],
    () => {
      let promises = [];
      selected_mc.forEach((m) => {
        let d = query_machines.data.find((f) => f && f.data && f.data._id === m);
        if (typeof d === "object" && d.data && d.data.iexurl) {
          let f = fetch_iex(startdate.toDate(), enddate.toDate(), d.data.iexurl).then((data) => {
            if (data && Array.isArray(data.values)) {
              let rows = [];
              data.values.forEach((d, i) => {
                if (i > 0 && d && d.length > 1) {
                  let d_date = new moment(d[0]);
                  d_date.set({ hour: 6, minute: 0, second: 0 });
                  if (d_date.isBetween(startdate, enddate)) {
                    let k = { date: d_date, mpeak: d.length > 1 ? f_comma_num(d[1], true) : 0, epeak: d.length > 2 ? f_comma_num(d[2], true) : 0, nonpeak: d.length > 3 ? f_comma_num(d[3], true) : null, mnonpeak: d.length > 4 ? f_comma_num(d[4], true) : null, enonpeak: d.length > 5 ? f_comma_num(d[5], true) : null };
                    rows.push(k);
                  }
                }
              });

              return rows;
            }
            return false;
          });
          promises.push(f);
        }
      });

      return Promise.all(promises).then((res) => {
        let rows = [];
        res.forEach((d) => {
          d.forEach((w) => {
            let found = rows.findIndex((r) => {
              if (!w.date) return false;
              return w.date.isSame(r.date, "date");
            });
            if (found > -1) {
              for (const [k, v] of Object.entries(w)) {
                if (typeof v === "number") {
                  rows[found][k] += v;
                }
              }
            } else {
              rows.push(w);
            }
          });
        });

        return rows;
      });
    },
    { enabled: typeof query_config.data === "object" && query_config.data.SUMMARY_IEX === "1" && Array.isArray(query_machines.data) }
  );

  const table_data = useMemo(() => {
    if (!query_summary.data) return false;
    // if (!query_iex.data) return false;
    let rows = [];

    function execute(obj, key) {
      obj.forEach((o) => {
        if (o) {
          o.forEach((e) => {
            if (e.length) {
              // This code will break with there is same type(con/gen) from 2 different servers. Need to be fixed. AAA - 4th Oct
              let d = e[0];
              if (d) {
                let found = rows.findIndex((p) => (p && d && p.date && d.date ? p.date === d.date : false));
                if (found === -1) {
                  rows.push({ date: d.date });
                  found = rows.length - 1;
                }
                rows[found][key] = d;
              }
            }
          });
        }
      });
    }

    execute(query_summary.data[0], "gen");
    execute(query_summary.data[1], "con");

    //IEX Data

    if (query_iex.data && Array.isArray(query_iex.data)) {
      query_iex.data.forEach((d) => {
        if (d.date) {
          let found = rows.findIndex((p) => {
            let p_date = p && p.date && new moment(p.date);
            if (!p_date) return false;
            return d.date.isSame(p_date, "date");
          });
          if (found > -1) {
            rows[found].iex = d;
          } else {
            console.error("query_iex.data date is missing in rows");
          }
        }
      });
    }

    //find the Balance
    let sum = {
      gen: { mpeak: 0, epeak: 0, nonpeak: 0, total: 0, mnonpeak: 0, enonpeak: 0 },
      gen_yield: { mpeak: 0, epeak: 0, nonpeak: 0, mnonpeak: 0, enonpeak: 0 },
      con: { mpeak: 0, epeak: 0, nonpeak: 0, total: 0, mnonpeak: 0, enonpeak: 0 },
      iex: { mpeak: 0, epeak: 0, nonpeak: 0, mnonpeak: 0, enonpeak: 0 },
      bal: { mpeak: 0, epeak: 0, nonpeak: 0, peak: 0, mnonpeak: 0, enonpeak: 0 },
    };

    rows.forEach((o) => {
      let gen = o.gen;
      let con = o.con;
      let iex = o.iex;

      if (gen && gen.peaks) {
        //Sum
        sum.gen.mpeak += gen.peaks.mpeak;
        sum.gen.epeak += gen.peaks.epeak;
        sum.gen.nonpeak += gen.peaks.nonpeak;
        sum.gen.total += gen.peaks.total;
        sum.gen.mnonpeak += gen.peaks.mnonpeak;
        sum.gen.enonpeak += gen.peaks.enonpeak;

        sum.gen_yield.mpeak += gen.peaks.mpeak_yield ?? 0;
        sum.gen_yield.epeak += gen.peaks.epeak_yield ?? 0;
        sum.gen_yield.nonpeak += gen.peaks.nonpeak_yield ?? 0;
        sum.gen_yield.total = (sum.gen_yield.total ?? 0) + (gen.peaks.mpeak_yield ?? 0) + (gen.peaks.epeak_yield ?? 0) + (gen.peaks.nonpeak_yield ?? 0) ?? 0;
        sum.gen_yield.mnonpeak += gen.peaks.mnonpeak_yield ?? 0;
        sum.gen_yield.enonpeak += gen.peaks.enonpeak_yield ?? 0;

        let _maxdemand = gen.peaks.maxdemand && gen.peaks.maxdemand.data && gen.peaks.maxdemand.data.power;
        sum.gen.maxdemand = Math.max(sum.gen.maxdemand ?? 0, _maxdemand ?? 0);
      }

      if (con && con.peaks) {
        sum.con.mpeak += con.peaks.mpeak;
        sum.con.epeak += con.peaks.epeak;
        sum.con.nonpeak += con.peaks.nonpeak;
        sum.con.total += con.peaks.total;
        sum.con.mnonpeak += con.peaks.mnonpeak;
        sum.con.enonpeak += con.peaks.enonpeak;

        let _maxdemand = con.peaks.maxdemand && con.peaks.maxdemand.data && con.peaks.maxdemand.data.power;
        sum.con.maxdemand = Math.max(sum.con.maxdemand ?? 0, _maxdemand ?? 0);
      }

      if (iex) {
        sum.iex.mpeak += iex.mpeak;
        sum.iex.epeak += iex.epeak;
        sum.iex.nonpeak += iex.nonpeak;
        sum.iex.total = (sum.iex.total ?? 0) + (iex.mpeak ?? 0) + (iex.epeak ?? 0) + (iex.nonpeak ?? 0);
        sum.iex.mnonpeak += iex.mnonpeak;
        sum.iex.enonpeak += iex.enonpeak;
      }

      if (gen && con && gen.peaks && con.peaks) {
        let bal_mpeak = (gen.peaks.mpeak_yield ?? 0) - (con.peaks.mpeak ?? 0);
        let bal_epeak = (gen.peaks.epeak_yield ?? 0) - (con.peaks.epeak ?? 0);
        let bal_nonpeak = (gen.peaks.nonpeak_yield ?? 0) - (con.peaks.nonpeak ?? 0);
        let bal_peak = (gen.peaks.mpeak_yield ?? 0) + (gen.peaks.epeak_yield ?? 0) - (con.peaks.mpeak ?? 0) - (con.peaks.epeak ?? 0);

        let bal_mnonpeak = (gen.peaks.mnonpeak_yield ?? 0) - (con.peaks.mnonpeak ?? 0);
        let bal_enonpeak = (gen.peaks.enonpeak_yield ?? 0) - (con.peaks.enonpeak ?? 0);

        if (iex) {
          bal_mpeak += iex.mpeak;
          bal_epeak += iex.epeak;
          bal_nonpeak += iex.nonpeak;
          bal_peak = bal_peak + iex.mpeak + iex.epeak;
          bal_mnonpeak += iex.mnonpeak;
          bal_enonpeak += iex.enonpeak;
        }

        o.bal = { date: o.date, peaks: { mpeak: bal_mpeak, epeak: bal_epeak, nonpeak: bal_nonpeak, peak: bal_peak, mnonpeak: bal_mnonpeak, enonpeak: bal_enonpeak } };

        sum.bal.mpeak += bal_mpeak;
        sum.bal.epeak += bal_epeak;
        sum.bal.nonpeak += bal_nonpeak;
        sum.bal.peak = sum.bal.peak + bal_mpeak + bal_epeak;
        sum.bal.mnonpeak += bal_mnonpeak;
        sum.bal.enonpeak += bal_enonpeak;
      }
    });

    return { rows, sum };
  }, [query_summary.data, query_iex.data]);

  const datepicker_ui = useMemo(() => {
    return (
      <Stack gap={3} direction="horizontal" className="mb-4 justify-content-center">
        <DateInputView title="From Date" selected={startdate && startdate.toDate()} onChange={(date) => setstartdate(moment(date))} />
        <DateInputView title="To Date" selected={enddate && enddate.toDate()} onChange={(date) => setenddate(moment(date).endOf("day"))} />
      </Stack>
    );
  }, [enddate, startdate]);

  const onClick = useCallback(
    (d) => {
      document.activeElement.blur();
      let idx = selected_mc.indexOf(d.data._id);
      if (idx > -1) {
        selected_mc.splice(idx, 1);
      } else selected_mc.push(d.data._id);
      setselected_mc([...selected_mc]);
    },
    [selected_mc, setselected_mc]
  );

  const machinelist_ui = useMemo(() => {
    return (
      <Container fluid className="my-5">
        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
          {query_machines.data &&
            query_machines.data.map((d, i) => {
              if (d && d.data && d.data.name) {
                return (
                  <Button key={`DayMachines_${i}`} className="p-3" variant={selected_mc.indexOf(d.data._id) > -1 ? "light" : "outline-light"} onClick={() => onClick(d)}>
                    {d.data.name}
                    <Form.Check type={"checkbox"} checked={selected_mc.indexOf(d.data._id) > -1} disabled />
                  </Button>
                );
              }
              return null;
            })}
        </Stack>
      </Container>
    );
  }, [onClick, query_machines.data, selected_mc]);

  return (
    <ReportContext.Provider value={{ datepicker_ui, table_data, query_config, query_machines, query_summary, startdate, enddate, color_accent, mcs }}>
      <Container fluid>
        {datepicker_ui}
        {machinelist_ui}
        {query_machines.isLoading || query_summary.isLoading ? <SpinnerView /> : query_machines.error || query_summary.error ? "ERROR" : <Outlet />}
      </Container>
    </ReportContext.Provider>
  );
}

export function DayReport(props) {
  let { state } = useLocation();
  const [date, setdate] = useState(new Date());
  let enabled = state !== null && typeof state.data === "object" ? true : false;
  let query = useQuery(["fetch_summary", enabled && state.data.data._id, date], () => fetch_summary(enabled && state.data.server, [enabled && state.data.data._id], date, null, true, true, true, true), { enabled });

  let query2 = useQuery("config_vars", get_config_vars);
  let color_accent = useMemo(() => (query2.data && query2.data.COMPANY_COLOR) ?? "#138AF2", [query2.data]);

  let istoday = useMemo(() => {
    if (state !== null && typeof state.data === "object" && date) {
      let m = new moment(date);
      var istoday = m.isSame(new Date(), "day");
      return istoday;
    }
    return false;
  }, [date, state]);

  let query_otherday = useQuery(
    ["fetch_summary_otherday", enabled && state.data.data._id, date],
    () => {
      if (date) {
        let m = new moment(date);
        var istoday = m.isSame(new Date(), "day");
        let previous_date = istoday ? new moment(date).subtract(1, "day").toDate() : new Date();
        return fetch_summary(state.data.server, [state.data.data._id], previous_date, null, true).then((res) => {
          let d = res && res.length && res[0].length && res[0][0];
          return d;
        });
      }
      return null;
    },
    { enabled }
  );

  useEffect(() => {
    if (date) {
      let date1 = new moment(date);
      if (date1.isSame(new Date(), "day")) {
        let date2 = new moment();
        date2.set({ hour: 6, minute: 59, second: 29, millisecond: 99 });
        if (date1.isBefore(date2)) {
          setdate(date1.subtract(1, "day").toDate());
        }
      }
    }
  }, []);

  let summary = useMemo(() => {
    let data = query.data && query.data.length && query.data[0] && query.data[0].length && query.data[0][0];
    if (data) {
      if (data.minutes) {
        data.minutes.forEach((d) => {
          d.timestamp = new Date(d.created_on).valueOf();
          if (d.data) {
            if (d.data.power && d.data.power < 100000) d.yvalue = d.data.power;
            let active_panels = 0;
            let keys = [1, 2, 3];
            var i = 0;
            keys.forEach((k) => {
              let p = d.data[`panel_inv_${k}`];
              if (p) {
                i++;
                p.forEach((v) => {
                  if (Array.isArray(v)) {
                    v.forEach((e) => {
                      if (e.a) active_panels++;
                    });
                  }
                });
              }
            });
            if (i === keys.length) {
              d.active_panels = active_panels;
            }
          }
        });
      }

      if (data.peaks && data.peaks.maxdemand) {
        let t = new Date(data.peaks.maxdemand.created_on).valueOf();
        data.peaks.maxdemand.timestamp = t;
      }
    }

    return data;
  }, [query.data]);

  if (query.isLoading) return <SpinnerView />;
  else if (query.error) return "Error";

  return (
    <Container fluid>
      <Outlet />
      <Container fluid className="text-center w-100 py-5">
        {/* <DatePicker dateFormat="MMMM do yyyy" selected={date} onChange={(date) => setdate(date)} customInput={<CustomDatePickerInput />} /> */}
        <DateInputView title="Date" selected={date} onChange={(date) => setdate(date)} />
      </Container>
      {summary && (
        <>
          <Row className="pb-5">
            <Col>
              <Stack className="text-center text-white">
                <h1 className="display-2 fw-bold text-shadow bg-light rounded text-dark mx-auto px-5 py-3 my-4">
                  <h5>{state.data.data.type === "con" ? "Consumption" : "Generation"}</h5>
                  {summary.peaks.total ? Math.round(summary.peaks.total) + " units" : null}
                </h1>
                {query_otherday.data && query_otherday.data.peaks && <h6>{(istoday ? `Yesterday` : `Today`) + ` Until ${new moment().format("h:mma")} : ` + (istoday ? Math.round(query_otherday.data.peaks.untilnow) : Math.round(query_otherday.data.peaks.total))}</h6>}
                {!istoday && summary.peaks && <h6>{`Until ${new moment().format("h:mma")} : ` + Math.round(summary.peaks.untilnow)}</h6>}
              </Stack>
            </Col>
          </Row>

          {summary.minutes && (
            <Row className={"w-100"} style={{ height: 550 }}>
              <ResponsiveContainer width={"98%"}>
                <AreaChart data={summary.minutes} margin={{ bottom: 50, top: 50, left: 10, right: 10 }}>
                  <defs>
                    <linearGradient id={"gradient1"} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="20%" stopColor={color_accent} stopOpacity={1} />
                      <stop offset="95%" stopColor={color_accent} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey={"timestamp"} tickFormatter={(d) => moment(d).format("h:mma")} />
                  <YAxis allowDataOverflow={true} allowDecimals={false} tickCount={6} interval={"preserveStartEnd"} tickSize={5} tickFormatter={(v) => Math.round(v)} />
                  <Area type="monotone" dataKey="yvalue" stroke={color_accent} dot={false} activeDot={{ r: 8 }} strokeWidth={4} isAnimationActive={true} fill={`url(#gradient1)`} />

                  <Tooltip
                    content={(o) => {
                      const { payload, label } = o;
                      if (!payload || payload.length === 0 || !label) return null;
                      let d = payload[0].payload;
                      return (
                        <div className="bg-body p-2 rounded">
                          {label && <h6>{moment(label).format("h:mm a")}</h6>}
                          <h6 className="fw-bolder">{Math.round(d.yvalue)} units</h6>
                          {d.active_panels ? <h6 className="fw-bolder">{Math.round(d.active_panels)} active panels</h6> : null}
                        </div>
                      );
                    }}
                  />
                  {summary.peaks && summary.peaks.maxdemand && summary.peaks.maxdemand.timestamp && <ReferenceLine x={summary.peaks.maxdemand.timestamp} stroke={color_accent} label={{ position: "top", value: `Max Demand ${summary.peaks.maxdemand.data && f_num(summary.peaks.maxdemand.data.power)} @ ${new moment(summary.peaks.maxdemand.timestamp).format("h:mm a")}`, fontSize: 16, fontWeight: "bold" }} />}
                </AreaChart>
              </ResponsiveContainer>
            </Row>
          )}

          {summary.startend && (
            <>
              {summary.startend.powerstarttime && summary.startend.powerendtime && (
                <Stack direction="horizontal" className="pb-5">
                  <GridBoxViewColumn k={"Start Time"} v={new moment(summary.startend.powerstarttime.created_on).format("h:mm a")} />
                  <GridBoxViewColumn k={"End Time"} v={new moment(summary.startend.powerendtime.created_on).format("h:mm a")} />
                </Stack>
              )}
              <Stack direction="horizontal" className="pb-5">
                <GridBoxViewColumn k={"M Peak"} v={summary.peaks.mpeak} />
                <GridBoxViewColumn k={"E Peak"} v={summary.peaks.epeak} />
                <GridBoxViewColumn k={"Non Peak"} v={summary.peaks.nonpeak} />
                <GridBoxViewColumn k={"M Non Peak"} v={summary.peaks.mnonpeak} />
                <GridBoxViewColumn k={"E Non Peak"} v={summary.peaks.enonpeak} />
              </Stack>
            </>
          )}

          {summary.hours && <GridBoxView data={summary.hours} keyfield={(h, i) => new moment(h.start).format("ha")} valuefield={(h, i) => h.value} />}
        </>
      )}
    </Container>
  );
}

export function SummaryReport(props) {
  let ctx = useContext(ReportContext);

  let show_iex = ctx.query_config.data && ctx.query_config.data.SUMMARY_IEX === "1";
  let show_con = ctx.query_config.data && ctx.query_config.data.SUMMARY_CONSUMPTION === "1";

  let view2 = true;
  return (
    ctx.table_data && (
      <>
        <Table striped bordered hover responsive size="sm" className={`mx-auto text-center border-dark`}>
          {/* <colgroup>
            <col colSpan={7} />
            <col colSpan={3} style={{ visibility: () === "1" ? "visible" : "collapse" }} />
            <col colSpan={5} style={{ visibility: ( === "1" ? "visible" : "collapse" }} />
          </colgroup> */}
          <thead>
            <tr>
              <th>Date</th>
              <th colSpan={view2 ? 4 : 6}>Generation</th>
              <th colSpan={view2 ? 3 : 5}>Generation Yield {ctx.table_data.length && `(${ctx.table_data[0].gen.lossperc})`}</th>
              {show_iex && <th colSpan={view2 ? 3 : 5}>IEX</th>}
              {show_con && <th colSpan={view2 ? 4 : 6}>Consumption</th>}
              {show_con && <th colSpan={view2 ? 2 : 4}>Balance</th>}
            </tr>
            <tr className={`${view2 && `dayreport_view2`}`}>
              <th></th>
              <th>Max Demand</th>
              <th>M Peak</th>
              <th>E Peak</th>
              <th>Non Peak</th>
              <th>M Non Peak</th>
              <th>E Non Peak</th>

              <th>M Peak</th>
              <th>E Peak</th>
              <th>Non Peak</th>
              <th>M Non Peak</th>
              <th>E Non Peak</th>

              {show_iex && (
                <>
                  <th>M Peak</th>
                  <th>E Peak</th>
                  <th>Non Peak</th>
                  <th>M Non Peak</th>
                  <th>E Non Peak</th>
                </>
              )}

              {show_con && (
                <>
                  <>
                    <th>Max Demand</th>
                    <th>M Peak</th>
                    <th>E Peak</th>
                    <th>Non Peak</th>
                    <th>M Non Peak</th>
                    <th>E Non Peak</th>
                  </>
                  <>
                    <th>Peak</th>
                    <th>Non Peak</th>
                    <th>M Non Peak</th>
                    <th>E Non Peak</th>
                  </>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {ctx.table_data &&
              ctx.table_data.rows.map((d) => (
                <tr className={`text-end ${view2 && `dayreport_view2`}`}>
                  <td className="text-center">{d.date ? new moment(d.date).format("D MMM") : null}</td>
                  <td>{d.gen && d.gen.peaks && d.gen.peaks.maxdemand && d.gen.peaks.maxdemand.data && d.gen.peaks.maxdemand.data.power && f_num(d.gen.peaks.maxdemand.data.power)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mpeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.epeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.nonpeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mnonpeak)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.enonpeak)}</td>

                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mpeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.epeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.nonpeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.mnonpeak_yield)}</td>
                  <td>{f_num(d.gen && d.gen.peaks && d.gen.peaks.enonpeak_yield)}</td>

                  {show_iex && (
                    <>
                      <td>{f_num(d.iex && d.iex.mpeak)}</td>
                      <td>{f_num(d.iex && d.iex.epeak)}</td>
                      <td>{f_num(d.iex && d.iex.nonpeak)}</td>
                      <td>{f_num(d.iex && d.iex.mnonpeak) ?? "-"}</td>
                      <td>{f_num(d.iex && d.iex.enonpeak) ?? "-"}</td>
                    </>
                  )}

                  {show_con && (
                    <>
                      <td>{d.con && d.con.peaks && d.con.peaks.maxdemand && d.con.peaks.maxdemand.data && d.con.peaks.maxdemand.data.power && f_num(d.con.peaks.maxdemand.data.power)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.mpeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.epeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.nonpeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.mnonpeak)}</td>
                      <td>{f_num(d.con && d.con.peaks && d.con.peaks.enonpeak)}</td>

                      {/* <td className={`${d.bal && d.bal.peaks && d.bal.peaks.mpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.mpeak)}</td>
                  <td className={`${d.bal && d.bal.peaks && d.bal.peaks.epeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.epeak)}</td> */}
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.peak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.peak)}</td>
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.nonpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.nonpeak)}</td>
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.mnonpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.mnonpeak)}</td>
                      <td className={`${d.bal && d.bal.peaks && d.bal.peaks.enonpeak < 0 ? "text-danger" : ""}`}>{f_num(d.bal && d.bal.peaks && d.bal.peaks.enonpeak)}</td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
          <tfoot>
            {ctx.table_data && ctx.table_data.sum && (
              <tr className={`text-end fw-bold ${view2 && `dayreport_view2`}`}>
                <td></td>
                <td>{round_decimals(ctx.table_data.sum.gen.maxdemand)}</td>

                <td>{f_num(ctx.table_data.sum.gen.mpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.epeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.nonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.mnonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen.enonpeak)}</td>

                <td>{f_num(ctx.table_data.sum.gen_yield.mpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.epeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.nonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.mnonpeak)}</td>
                <td>{f_num(ctx.table_data.sum.gen_yield.enonpeak)}</td>

                {show_iex && (
                  <>
                    <td>{f_num(ctx.table_data.sum.iex.mpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.epeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.nonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.mnonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.iex.enonpeak)}</td>
                  </>
                )}

                {show_con && (
                  <>
                    <td>{round_decimals(ctx.table_data.sum.con.maxdemand)}</td>
                    <td>{f_num(ctx.table_data.sum.con.mpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.epeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.nonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.mnonpeak)}</td>
                    <td>{f_num(ctx.table_data.sum.con.enonpeak)}</td>
                    {/* <td className={`${table_data.sum.bal.mpeak < 0 ? "text-danger" : ""}`}>{f_num(table_data.sum.bal.mpeak)}</td> */}
                    {/* <td className={`${table_data.sum.bal.epeak < 0 ? "text-danger" : ""}`}>{f_num(table_data.sum.bal.epeak)}</td> */}
                    <td className={`${ctx.table_data.sum.bal.peak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.peak)}</td>
                    <td className={`${ctx.table_data.sum.bal.nonpeak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.nonpeak)}</td>
                    <td className={`${ctx.table_data.sum.bal.mnonpeak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.mnonpeak)}</td>
                    <td className={`${ctx.table_data.sum.bal.enonpeak < 0 ? "text-danger" : ""}`}>{f_num(ctx.table_data.sum.bal.enonpeak)}</td>
                  </>
                )}
              </tr>
            )}
          </tfoot>
        </Table>
        <Outlet />
      </>
    )
  );
}

export function MonthReport(props) {
  let { state } = useLocation();
  const [year, setyear] = useState(moment().year());
  let enabled = state !== null && typeof state.data === "object" ? true : false;
  let query = useQuery(["fetch_summary_month", state && state.data && state.data.data._id, year], () => fetch_summary_monthly(state.data.server, [state.data.data._id], moment(`1-1-${year}`, "MM-DD-YYYY").startOf("year").toDate(), moment(`1-1-${year}`, "MM-DD-YYYY").endOf("year").toDate()), { enabled });

  // Consumption - MPeak , EPeak , Non Peak
  //Generation - MPeak , EPeak , Non Peak
  //Generation Yield - MPeak , EPeak , Non Peak
  // IEX - MPeak - EPeak , Non Peak

  let data = query.data;
  return (
    <Container fluid>
      <Outlet />
      <Container fluid className="text-center w-100 py-5">
        <YearDropdown selected={year} onChange={(year) => setyear(year)} />
        {query.isLoading ? (
          <SpinnerView />
        ) : query.error ? (
          "ERROR"
        ) : (
          data && (
            <>
              <Table striped bordered hover responsive size="sm">
                <thead className="text-center">
                  <tr>
                    <th>Date</th>
                    {/* <th colSpan={1}>M Peak</th>
                    <th colSpan={1}>E Peak</th>
                    <th colSpan={1}>Non Peak</th> */}
                    <th colSpan={1}>Total_Yield</th>
                    <th colSpan={1}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((v) => {
                      if (v.length) {
                        let d = v[0];
                        if (d && d.date) {
                          return (
                            <tr className="text-end">
                              <td className="text-center">{d.date ? new moment(d.date).format("MMM YYYY") : null}</td>
                              {/* <td>{f_num(d.peaks && d.peaks.mpeak)}</td>
                          <td>{f_num(d.peaks && d.peaks.epeak)}</td>
                          <td>{f_num(d.peaks && d.peaks.nonpeak)}</td> */}
                              <td>{f_num(d.peaks && d.peaks.total_yield)}</td>
                              <td>{f_num(d.peaks && d.peaks.total)}</td>
                            </tr>
                          );
                        }
                      }
                      return <tr />;
                    })}
                </tbody>
              </Table>
              <Outlet />
            </>
          )
        )}
      </Container>
    </Container>
  );
}

export function CalendarReport(props) {
  let ctx = useContext(ReportContext);

  let data_startdate = useMemo(() => ctx.table_data && ctx.table_data.rows.length && ctx.table_data.rows[0].date && moment(ctx.table_data.rows[0].date), [ctx.table_data]);
  let data_enddate = useMemo(() => ctx.table_data && ctx.table_data.rows.length && ctx.table_data.rows[ctx.table_data.rows.length - 1].date && moment(ctx.table_data.rows[ctx.table_data.rows.length - 1].date), [ctx.table_data]);

  let body_ui = useMemo(() => {
    let temp = [...ctx.table_data.rows];

    // Start Add Dummy
    let _s = [];
    if (data_startdate) {
      let startdate_idx = data_startdate.isoWeekday();
      for (var i = 7 - startdate_idx + 1; i < 7; i++) {
        _s.push({
          date: moment(data_startdate)
            .subtract(i - startdate_idx + 1, "days")
            .toISOString(),
        });
      }
      temp = _s.reverse().concat(temp);
    }

    //End Add Dummy
    if (data_enddate) {
      let enddate_idx = data_enddate.isoWeekday();
      for (var h = enddate_idx + 1; h <= 7; h++) {
        temp.push({
          date: moment(data_enddate)
            .add(h - enddate_idx, "days")
            .toISOString(),
        });
      }
    }

    let c = chunk(temp, 7);
    let arr = [];

    let dow_sum = Array.from({ length: 7 }, () => ({ gen: 0, con: 0 }));

    for (var j = 0; j < c.length; j++) {
      let tr = [];

      let week_sum = { gen: 0, con: 0 };

      for (var k = 0; k < c[j].length; k++) {
        let d = c[j][k];

        let dow = moment(d.date).isoWeekday();
        //DOW & Weekly Sum
        if (d.gen && d.gen.peaks && d.gen.peaks.total) {
          let v = d.gen.peaks.total;
          week_sum.gen += v;
          dow_sum[dow - 1].gen += v;
        }

        if (d.con && d.con.peaks && d.con.peaks.total) {
          let v = d.con.peaks.total;
          week_sum.con += v;
          dow_sum[dow - 1].con += v;
        }

        let td = (
          <td style={{ height: "90px" }} className="p-2 text-center">
            <h6>{moment(d.date).format("D MMM")}</h6>
            <Stack gap={1}>
              {d.gen && <LabelBadge title={"GEN"} value={d.gen.peaks.total} />}
              {d.con && <LabelBadge title={"CON"} value={d.con.peaks.total} />}
            </Stack>
          </td>
        );
        tr.push(td);
      }

      //Week SUM TR
      tr.push(
        <td style={{ height: "90px", borderLeftWidth: 5 }} className="p-2 text-center">
          <h6 style={{ color: ctx.color_accent ?? "inherit" }}>Week Total</h6>
          <Stack gap={1}>
            {week_sum.gen > 0 && <LabelBadge title={"GEN"} value={week_sum.gen} />}
            {week_sum.con > 0 && <LabelBadge title={"CON"} value={week_sum.con} />}
          </Stack>
        </td>
      );
      arr.push(<tr>{tr}</tr>);
    }

    //DOW SUM TR
    dow_sum.push({ gen: ctx.table_data.sum.gen.total, con: ctx.table_data.sum.con.total }); // Total of Date Range

    let foot = dow_sum.map((o) => {
      return (
        <td style={{ height: "90px", borderTopWidth: 5 }} className="p-2 text-center">
          <h6 style={{ color: ctx.color_accent ?? "inherit" }}>Total</h6>
          <Stack gap={1}>
            {o.gen > 0 && <LabelBadge title={"GEN"} value={o.gen} />}
            {o.con > 0 && <LabelBadge title={"CON"} value={o.con} />}
          </Stack>
        </td>
      );
    });

    arr.push(<tr>{foot}</tr>);

    return arr;
  }, [ctx.color_accent, ctx.table_data.rows, data_enddate, data_startdate]);

  return (
    <>
      <Table striped bordered hover responsive size="sm" className="mx-auto text-light">
        <thead>
          {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Week Total"].map((d) => (
            <th className="text-center">{d}</th>
          ))}
        </thead>
        <tbody>{body_ui}</tbody>
      </Table>
    </>
  );
}

export function EBReport(props) {
  let ctx = useContext(ReportContext);
  let d = ctx.table_data;

  const calc = useCallback(
    (no_gen) => {
      let m_gen_meter_count = 0;
      let m_con_meter_count = 0;
      let demand_sanctioned = 0;
      let gen_total_mw = 0;

      let _gen_yield = no_gen
        ? {
            mpeak: 0,
            epeak: 0,
            nonpeak: 0,
            maxdemand: 0,
            total: 0,
          }
        : d.sum.gen_yield;

      let _iex = no_gen
        ? {
            mpeak: 0,
            epeak: 0,
            nonpeak: 0,
            maxdemand: 0,
            total: 0,
          }
        : d.sum.iex;

      if (ctx && ctx.mcs) {
        m_con_meter_count = Array.isArray(ctx.mcs.con) ? ctx.mcs.con.length : 0;
        m_gen_meter_count = Array.isArray(ctx.mcs.gen) ? ctx.mcs.gen.length : 0;

        if (Array.isArray(ctx.mcs.con)) {
          demand_sanctioned = (ctx.mcs.con.reduce((p, v, i) => v.data.mw + p, 0) ?? 0) * 1000;
        }

        if (Array.isArray(ctx.mcs.gen)) {
          gen_total_mw = ctx.mcs.gen.reduce((p, v, i) => v.data.mw + p, 0) ?? 0;
        }
      }

      if (!m_con_meter_count) {
        // alert("ERROR : m_meter_count is 0 !");
        return;
      } else if (!demand_sanctioned) {
        // alert("ERROR : demand_sanctioned is 0 !");
        return;
      }

      let demand_max = d.sum.con.maxdemand;
      let demand_charged = Math.max(demand_sanctioned * 0.9, demand_max);
      let m_demand_rate = 589;
      let demand_total = demand_charged * m_demand_rate;
      let tneb_peaktotal = d.sum.con.mpeak + d.sum.con.epeak - _gen_yield.mpeak - _gen_yield.epeak - _iex.mpeak - _iex.epeak;
      let tneb_nonpeaktotal = Math.max(0, d.sum.con.nonpeak - _gen_yield.nonpeak - _iex.nonpeak);
      let tneb_total = tneb_peaktotal + tneb_nonpeaktotal;

      let m_industrial_con = 7.25;
      let industrial_con = tneb_total * m_industrial_con;

      let m_peakhour_con = 1.8125;
      let peakhour_con = tneb_peaktotal * m_peakhour_con;

      let m_nonpeakhour_con = 0.3625;
      let nonpeakhour_con = -(tneb_nonpeaktotal * m_nonpeakhour_con);

      let total_energycharges = industrial_con + peakhour_con + nonpeakhour_con;

      let total_energycharges_demand = total_energycharges + demand_total;

      let m_meter_rent = 4572.5;

      let meter_rent = m_meter_rent * m_con_meter_count;

      let m_cross_subsidy_charges = 2.2656;
      let cross_subsidy_charges = m_cross_subsidy_charges * _iex.total;

      let m_electricity_tax_perc = 5;
      let electricity_tax = (industrial_con + peakhour_con) * (m_electricity_tax_perc / 100);

      let assesment_amount = total_energycharges_demand + meter_rent + cross_subsidy_charges + electricity_tax;

      let m_self_gen_tax_perc = 10;
      let self_gen_tax = _gen_yield.total * (m_self_gen_tax_perc / 100);

      let m_iex_etax_perc = 10;
      let iex_etax = _iex.total * (m_iex_etax_perc / 100);

      let noofdays = ctx.enddate.diff(ctx.startdate, "days") + 1;
      let m_amr_meter_charges = no_gen ? 0 : 430;
      let amr_meter_charges = m_gen_meter_count * m_amr_meter_charges;

      let m_transmisson_charges = no_gen ? 0 : 2790.134;
      let transmission_charges = m_transmisson_charges * gen_total_mw * noofdays;

      let m_system_operation_charges = no_gen ? 0 : 46.231;
      let system_operation_charges = m_system_operation_charges * gen_total_mw * noofdays;

      let m_rkvar_penalty = 1250;
      let rkvar_penalty = m_rkvar_penalty * gen_total_mw;

      let m_import_energy_charges = no_gen ? 0 : 18000;
      let import_energy_charges = m_import_energy_charges * m_gen_meter_count;

      let m_scheduling_charges = no_gen ? 0 : 100;
      let scheduling_charges = m_scheduling_charges * noofdays * m_gen_meter_count;

      let oa_total_charges = amr_meter_charges + transmission_charges + system_operation_charges + rkvar_penalty + import_energy_charges + scheduling_charges;

      let m_wheeling_iex_charges = 1.06063;
      let wheeling_iex_charges = m_wheeling_iex_charges * _iex.total;

      let m_wheeling_solar_charges = 0.5345;
      let wheeling_solar_charges = m_wheeling_solar_charges * _gen_yield.total;

      let wheeling_total_charges = wheeling_iex_charges + wheeling_solar_charges;

      let adjustment_charges = oa_total_charges + wheeling_total_charges;

      let net_payable = assesment_amount + self_gen_tax + iex_etax + adjustment_charges;

      return {
        demand_sanctioned,
        demand_max,
        demand_charged,
        m_demand_rate,
        demand_total,

        tneb_total,
        tneb_peaktotal,
        tneb_nonpeaktotal,

        m_industrial_con,
        industrial_con,

        m_peakhour_con,
        peakhour_con,

        m_nonpeakhour_con,
        nonpeakhour_con,

        total_energycharges,

        total_energycharges_demand,

        meter_rent,
        m_con_meter_count,
        m_gen_meter_count,
        m_meter_rent,

        cross_subsidy_charges,
        m_cross_subsidy_charges,

        m_electricity_tax_perc,
        electricity_tax,

        m_self_gen_tax_perc,
        self_gen_tax,

        m_iex_etax_perc,
        iex_etax,

        adjustment_charges,

        gen_total_mw,
        noofdays,
        m_amr_meter_charges,
        amr_meter_charges,
        m_transmisson_charges,
        transmission_charges,
        m_system_operation_charges,
        system_operation_charges,
        m_rkvar_penalty,
        rkvar_penalty,
        m_import_energy_charges,
        import_energy_charges,
        m_scheduling_charges,
        scheduling_charges,
        oa_total_charges,

        m_wheeling_iex_charges,
        wheeling_iex_charges,
        m_wheeling_solar_charges,
        wheeling_solar_charges,
        wheeling_total_charges,
        assesment_amount,

        net_payable,

        _gen_yield,
        _iex,
      };
    },
    [ctx, d.sum.con.epeak, d.sum.con.maxdemand, d.sum.con.mpeak, d.sum.con.nonpeak, d.sum.gen_yield, d.sum.iex]
  );

  let k = useMemo(() => {
    return calc();
  }, [calc]);

  let k_nongen = useMemo(() => {
    let r = calc(true);
    return r;
  }, [calc]);

  if (!d) return null;

  function row(d, title, value, no, subtitle, isfooter, style) {
    return (
      <tr className={`${isfooter ? "fw-bold" : null}`} style={style}>
        <td style={{ color: "inherit" }}>{no}</td>
        <td style={{ color: "inherit" }}>
          {title}
          <br />
          {subtitle && <small className={isfooter ? "fw-normal" : null}>{subtitle}</small>}
        </td>
        <td style={{ color: "inherit" }} className="text-end">
          {value}
        </td>
      </tr>
    );
  }

  if (!k) {
    return;
  }

  return (
    <Table>
      <tbody>
        {row(d, "Generation Peak", f_num(k._gen_yield.mpeak + k._gen_yield.epeak), 1.1)}
        {row(d, "Generation Non Peak", f_num(k._gen_yield.nonpeak), 1.2)}
        {row(d, "Generation Total", f_num(k._gen_yield.total), 1, null, true)}

        <br />
        {row(d, "Consumption Peak", f_num(d.sum.con.mpeak + d.sum.con.epeak), 2.1)}
        {row(d, "Consumption Non Peak", f_num(d.sum.con.nonpeak), 2.2)}
        {row(d, "Consumption Total", f_num(d.sum.con.total), 2, null, true)}

        <br />
        {row(d, "IEX Peak", f_num(k._iex.mpeak + k._iex.epeak), 3.1)}
        {row(d, "IEX Non Peak", f_num(k._iex.nonpeak), 3.2)}
        {row(d, "IEX Total", f_num(k._iex.total), 3, null, true)}

        <br />
        {/* {row(d, "TNEB Total", f_num(k.tneb_total), 4.1)} */}
        {row(d, "TNEB Peak Total", f_num(k.tneb_peaktotal), 4.2)}
        {row(d, "TNEB Non Peak Total", f_num(k.tneb_nonpeaktotal), 4.3)}
        <br />
        {row(d, "Sanctioned Demand", f_num(k.demand_sanctioned), 5.1)}
        {row(d, "Max Demand", f_num(k.demand_max), 5.2)}
        {row(d, "Demand Charges", f_num(k.demand_total), 5, `${k.m_demand_rate} ₹/unit * ${f_num(k.demand_charged)}`, true)}
        <br />
        {row(d, "Industrial Charges", f_num(k.industrial_con), 6.1, `${k.m_industrial_con} ₹/unit * ${f_num(k.tneb_total)}`)}
        {row(d, "Peak Hour Charges", f_num(k.peakhour_con), 6.2, `${k.m_peakhour_con} ₹/unit * ${f_num(k.tneb_peaktotal)}`)}
        {row(d, "Non Peak Hour Charges", f_num(k.nonpeakhour_con), 6.3, `${k.m_nonpeakhour_con} ₹/unit * ${f_num(k.tneb_nonpeaktotal)}`)}
        {row(d, "Total Energy Charges", f_num(k.total_energycharges), 6, null, true)}
        <br />
        {row(d, "Total Demand & Energy Charges", f_num(k.total_energycharges_demand), 7)}
        <br />
        {row(d, "Meter Rent", f_num(k.meter_rent), 8, `${k.m_meter_rent} ₹/meter * ${f_num(k.m_con_meter_count)} meters`)}
        <br />
        {row(d, "Cross Subsidy Charges (Incl. 18% GST)", f_num(k.cross_subsidy_charges), 9, `${f_num(k._iex.total)} units * ${k.m_cross_subsidy_charges} ₹/unit`)}
        <br />
        {row(d, "Electricity Tax", f_num(k.electricity_tax), 10, `${f_num(k.industrial_con + k.peakhour_con)} units * ${k.m_electricity_tax_perc} %`)}
        <br />
        {row(d, "Total Assessment Amount", f_num(k.assesment_amount), 11, `${f_num(k.assesment_amount)} ₹/meter  * ${k.m_con_meter_count} meters`, true)}
        <br />
        {row(d, "Self Generation Tax", f_num(k.self_gen_tax), 12, `${f_num(d.sum.gen_yield.total)} units  * ${k.m_self_gen_tax_perc}%`)}

        <br />
        {row(d, "IEX E Tax", f_num(k.iex_etax), 13, `${f_num(k._iex.total)} units  * ${k.m_iex_etax_perc}%`)}

        <br />
        {row(d, "AMR Meter Reading Charges", f_num(k.amr_meter_charges), 14.1, `${f_num(k.m_amr_meter_charges)} ₹/meter  * ${k.m_gen_meter_count} meters`)}
        {row(d, "Transmission Charges", f_num(k.transmission_charges), 14.2, `${f_num(k.m_transmisson_charges)} ₹/MW  * ${k.gen_total_mw} MW * ${k.noofdays} days`)}
        {row(d, "System Operation Charges", f_num(k.system_operation_charges), 14.3, `${f_num(k.m_system_operation_charges)} ₹/MW  * ${k.gen_total_mw} MW * ${k.noofdays} days`)}
        {row(d, "RKVAR Penalty", f_num(k.rkvar_penalty), 14.4, `${f_num(k.m_rkvar_penalty)} ₹/MW  * ${k.gen_total_mw} MW`)}
        {row(d, "Import Energy Charges", f_num(k.import_energy_charges), 14.5, `${f_num(k.m_import_energy_charges)} ₹/meter  * ${k.m_gen_meter_count} meters`)}
        {row(d, "Scheduling Charges", f_num(k.scheduling_charges), 14.6, `${f_num(k.m_scheduling_charges)} ₹/day  * ${k.noofdays} days * ${k.m_gen_meter_count} meters`)}
        {row(d, "Total OA Charges", f_num(k.oa_total_charges), 14, null, true)}

        <br />
        {row(d, "IEX Wheeling Charges", f_num(k.wheeling_iex_charges), 15.1, `${k.m_wheeling_iex_charges} ₹/unit  * ${f_num(k._iex.total)} units`)}
        {row(d, "Solar Wheeling Charges", f_num(k.wheeling_solar_charges), 15.2, `${k.m_wheeling_solar_charges} ₹/unit  * ${f_num(k._gen_yield.total)} units`)}
        {row(d, "Total Wheeling Charges", f_num(k.wheeling_total_charges), 15, null, true)}

        <br />
        {row(d, "Adjustment Charges", f_num(k.adjustment_charges), 16, null, true)}

        <br />
        {row(d, "Net Payable", f_num(k.net_payable), 17, null, true, { color: ctx.color_accent })}
        <br />
        {row(d, "Net Payable (Without Solar & IEX)", f_num(k_nongen.net_payable), 17.1, null, true)}
        {/* {row(d, "Net Savings", f_num(k_nongen.net_payable - k.net_payable), 17.2, null, true)} */}
      </tbody>
    </Table>
  );
}
