import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";

import "./App.css";

import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Container } from "react-bootstrap";
import { AboutView, NavBar } from "./components/CommonViews";
import moment from "moment";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
  },
});

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});
console.log(moment().startOf("week").toDate());

if (window.location.protocol === "https:") {
  alert(`You are accessing the website via https://. Please replace "https://" with "http://"`);
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Container fluid className="bg-dark py-4">
        <NavBar />
        <Container fluid className="pt-5" style={{ minHeight: "100vh" }}>
          <Outlet />
        </Container>
        <AboutView />
      </Container>
    </QueryClientProvider>
  );
}

export default App;
