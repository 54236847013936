import * as React from "react";
import "./App.css";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements } from "react-router-dom";
import App from "./App";
import { CalendarReport, DayMachines, DayReport, EBReport, MonthReport, ReportProvider, SummaryReport } from "./pages/DayReport";
import { Status } from "./pages/Status";
import "resize-observer-polyfill/dist/ResizeObserver.global";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="day" element={<DayMachines />}>
        <Route path=":machine" element={<DayReport />} />
      </Route>
      <Route path="month" element={<DayMachines />}>
        <Route path=":machine" element={<MonthReport />} />
      </Route>
      <Route path="report" element={<ReportProvider />}>
        <Route path="calendar" element={<CalendarReport />} />
        <Route path="summary" index element={<SummaryReport />} />
        <Route path="eb" index element={<EBReport />} />
      </Route>
      <Route path="status" index element={<Status />} />
    </Route>
  )
);

createRoot(document.getElementById("root")).render(<RouterProvider router={router} />);
